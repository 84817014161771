import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ErrorBoundary } from 'react-error-boundary';
import Navbar from './components/Navbar';
import LoadingSpinner from './components/LoadingSpinner';
import AuthModal from './components/AuthModal';
import { AuthProvider } from './contexts/AuthContext';
import CookieConsent from './components/CookieConsent';

// Lazy load pages
const Home = React.lazy(() => import('./pages/Home'));
const PropertyDetails = React.lazy(() => import('./pages/PropertyDetails'));
const CreateActivity = React.lazy(() => import('./pages/CreateActivity'));
const BusinessManagement = React.lazy(() => import('./pages/BusinessManagement'));
const Social = React.lazy(() => import('./pages/Social'));
const Messages = React.lazy(() => import('./pages/Messages'));
const Profile = React.lazy(() => import('./pages/Profile'));
const SavedLists = React.lazy(() => import('./pages/SavedLists'));
const SavedListDetails = React.lazy(() => import('./pages/SavedListDetails'));

// Legal Pages
const PrivacyPolicy = React.lazy(() => import('./pages/legal/PrivacyPolicy'));
const TermsOfService = React.lazy(() => import('./pages/legal/TermsOfService'));
const CookiePolicy = React.lazy(() => import('./pages/legal/CookiePolicy'));
const AccessibilityStatement = React.lazy(() => import('./pages/legal/AccessibilityStatement'));

function ErrorFallback({ error }: { error: Error }) {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="text-center">
        <h1 className="text-2xl font-bold text-gray-900 mb-4">Something went wrong</h1>
        <p className="text-gray-600 mb-4">{error.message}</p>
        <button
          onClick={() => window.location.reload()}
          className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
        >
          Reload Page
        </button>
      </div>
    </div>
  );
}

function App() {
  const [showAuthModal, setShowAuthModal] = React.useState(false);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <HelmetProvider>
        <AuthProvider>
          <Router>
            <div className="min-h-screen bg-gray-50">
              <Navbar onAuthClick={() => setShowAuthModal(true)} />
              <main className="container mx-auto px-4 py-8">
                <Suspense fallback={
                  <div className="flex justify-center items-center min-h-[60vh]">
                    <LoadingSpinner />
                  </div>
                }>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/property/:id" element={<PropertyDetails />} />
                    <Route path="/create-activity" element={<CreateActivity />} />
                    <Route path="/manage-business" element={<BusinessManagement />} />
                    <Route path="/social" element={<Social />} />
                    <Route path="/messages" element={<Messages />} />
                    <Route path="/profile/:username" element={<Profile />} />
                    <Route path="/saved-lists" element={<SavedLists />} />
                    <Route path="/saved-lists/:id" element={<SavedListDetails />} />
                    
                    {/* Legal Routes */}
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/terms" element={<TermsOfService />} />
                    <Route path="/cookies" element={<CookiePolicy />} />
                    <Route path="/accessibility" element={<AccessibilityStatement />} />
                  </Routes>
                </Suspense>
              </main>
              {showAuthModal && (
                <AuthModal onClose={() => setShowAuthModal(false)} />
              )}
              <CookieConsent />
            </div>
          </Router>
        </AuthProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
}

export default App;