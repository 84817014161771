import CookieConsent from 'react-cookie-consent';

export default function CookieConsentBanner() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="explore_more_consent"
      style={{ background: "#2B373B" }}
      buttonStyle={{ background: "#4F46E5", color: "white", fontSize: "13px" }}
      expires={150}
    >
      This website uses cookies to enhance the user experience. By continuing to browse the site, you agree to our use of cookies.{" "}
      <a href="/privacy" className="text-indigo-300 hover:text-indigo-200">
        Learn more
      </a>
    </CookieConsent>
  );
}