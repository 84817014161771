import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MapPin, User, MessageCircle, Globe, BookmarkPlus, Bell, Menu, X } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';

interface Notification {
  id: string;
  type: string;
  content: string;
  read: boolean;
  created_at: string;
}

export default function Navbar({ onAuthClick }: { onAuthClick: () => void }) {
  const { user } = useAuth();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      loadNotifications();
      
      // Subscribe to new notifications
      const channel = supabase
        .channel('notifications')
        .on('postgres_changes', {
          event: 'INSERT',
          schema: 'public',
          table: 'notifications',
          filter: `user_id=eq.${user.id}`
        }, () => {
          loadNotifications();
        })
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    }
  }, [user]);

  const loadNotifications = async () => {
    try {
      // Get notifications from the last 24 hours only
      const oneDayAgo = new Date();
      oneDayAgo.setDate(oneDayAgo.getDate() - 1);

      const { data, error } = await supabase
        .from('notifications')
        .select('*')
        .eq('user_id', user?.id)
        .gt('created_at', oneDayAgo.toISOString())
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      if (data) {
        setNotifications(data);
        setUnreadCount(data.filter(n => !n.read).length);
      }
    } catch (error) {
      console.error('Error loading notifications:', error);
      setError('Failed to load notifications');
    }
  };

  const markNotificationsAsRead = async () => {
    if (!user || unreadCount === 0) return;

    try {
      // Get IDs of unread notifications
      const unreadIds = notifications
        .filter(n => !n.read)
        .map(n => n.id);

      // Update notifications in the database
      const { error } = await supabase
        .from('notifications')
        .update({ read: true })
        .in('id', unreadIds);

      if (error) throw error;
      
      // Update local state
      setNotifications(prev => prev.map(n => ({ ...n, read: true })));
      setUnreadCount(0);
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  const handleNotificationsClick = () => {
    if (!showNotifications && unreadCount > 0) {
      markNotificationsAsRead();
    }
    setShowNotifications(!showNotifications);
  };

  return (
    <nav className="bg-white shadow-lg">
      <div className="container mx-auto px-3 sm:px-4">
        <div className="flex items-center justify-between h-14 sm:h-16">
          <Link to="/" className="flex items-center">
            <MapPin className="h-5 w-5 sm:h-6 sm:w-6 text-indigo-600" />
            <span className="font-bold text-lg sm:text-xl ml-2">Explore More</span>
          </Link>

          {/* Mobile Menu Button */}
          <div className="flex items-center gap-2 md:hidden">
            {user && (
              <div className="relative">
                <button
                  onClick={handleNotificationsClick}
                  className="p-1.5 hover:bg-gray-100 rounded-full relative"
                >
                  <Bell className="h-5 w-5 text-gray-600" />
                  {unreadCount > 0 && (
                    <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                      {unreadCount}
                    </span>
                  )}
                </button>
              </div>
            )}
            <button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="p-1.5 hover:bg-gray-100 rounded-lg"
            >
              {mobileMenuOpen ? (
                <X className="h-5 w-5 text-gray-600" />
              ) : (
                <Menu className="h-5 w-5 text-gray-600" />
              )}
            </button>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-2">
            {user ? (
              <>
                <Link to="/saved-lists" className="p-1.5 hover:bg-gray-100 rounded-full" title="Saved Lists">
                  <BookmarkPlus className="h-5 w-5 text-gray-600" />
                </Link>
                <Link to="/social" className="p-1.5 hover:bg-gray-100 rounded-full" title="Social Feed">
                  <Globe className="h-5 w-5 text-gray-600" />
                </Link>
                <Link to="/messages" className="p-1.5 hover:bg-gray-100 rounded-full" title="Messages">
                  <MessageCircle className="h-5 w-5 text-gray-600" />
                </Link>
                <div className="relative">
                  <button
                    onClick={handleNotificationsClick}
                    className="p-1.5 hover:bg-gray-100 rounded-full relative"
                  >
                    <Bell className="h-5 w-5 text-gray-600" />
                    {unreadCount > 0 && (
                      <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                        {unreadCount}
                      </span>
                    )}
                  </button>
                </div>
                <Link to="/profile/me" className="p-1.5 hover:bg-gray-100 rounded-full">
                  <User className="h-5 w-5 text-gray-600" />
                </Link>
              </>
            ) : (
              <button
                onClick={onAuthClick}
                className="px-3 py-1.5 text-sm bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Sign In
              </button>
            )}
          </div>
        </div>

        {/* Mobile Menu */}
        {mobileMenuOpen && (
          <div className="md:hidden border-t pt-2 pb-3">
            {user ? (
              <div className="grid grid-cols-4 gap-2">
                <Link
                  to="/saved-lists"
                  className="flex flex-col items-center p-2 hover:bg-gray-50 rounded-lg"
                >
                  <BookmarkPlus className="h-5 w-5 text-gray-600" />
                  <span className="text-xs mt-1">Lists</span>
                </Link>
                <Link
                  to="/social"
                  className="flex flex-col items-center p-2 hover:bg-gray-50 rounded-lg"
                >
                  <Globe className="h-5 w-5 text-gray-600" />
                  <span className="text-xs mt-1">Social</span>
                </Link>
                <Link
                  to="/messages"
                  className="flex flex-col items-center p-2 hover:bg-gray-50 rounded-lg"
                >
                  <MessageCircle className="h-5 w-5 text-gray-600" />
                  <span className="text-xs mt-1">Messages</span>
                </Link>
                <Link
                  to="/profile/me"
                  className="flex flex-col items-center p-2 hover:bg-gray-50 rounded-lg"
                >
                  <User className="h-5 w-5 text-gray-600" />
                  <span className="text-xs mt-1">Profile</span>
                </Link>
              </div>
            ) : (
              <button
                onClick={onAuthClick}
                className="w-full px-3 py-2 text-sm bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Sign In
              </button>
            )}
          </div>
        )}

        {/* Notifications Dropdown */}
        {showNotifications && (
          <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg border border-gray-200 z-50">
            <div className="p-3 border-b">
              <h3 className="font-semibold">Notifications</h3>
            </div>
            <div className="max-h-96 overflow-y-auto">
              {error ? (
                <div className="p-4 text-center text-red-600 text-sm">
                  {error}
                </div>
              ) : notifications.length === 0 ? (
                <div className="p-4 text-center text-gray-500 text-sm">
                  No notifications
                </div>
              ) : (
                notifications.map((notification) => (
                  <div
                    key={notification.id}
                    className={`p-3 border-b hover:bg-gray-50 ${
                      !notification.read ? 'bg-blue-50' : ''
                    }`}
                  >
                    <p className="text-sm">{notification.content}</p>
                    <p className="text-xs text-gray-500 mt-1">
                      {new Date(notification.created_at).toLocaleDateString()}
                    </p>
                  </div>
                ))
              )}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}